.row_control {

    &__wrapper {
        height: 100%;
        padding: 2px;

        &__col {
            height: 100%;
            padding: 3px;

            &__content {
                height: 100%;
                border: 2px solid;
            }
        }
    }

    &__label {
        display: block;
    }
}

.row_container {
    margin-left: 5%;
    margin-right: 5%;
    display: block;
    position: relative;


    &.hover::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border: 3px solid #0198fa;
        top: 0;
        left: 0;
        box-sizing: border-box;
        pointer-events: none;
        z-index: 9 !important;
    }


    &.hover {
        .row_container__sibling {
            box-shadow: 0 0 0 3px #0198fa inset;
        }
    }

    &__sibling {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;

        &:hover {
            // outline: 3px solid #0198fa;
            box-shadow: 0 0 0 3px #0198fa inset;
        }
    }

    .row_highlight {
        position: absolute;
        display: flex;
        height: 30px;
        top: -28px;
        right: 0px;
        pointer-events: auto;
        color: rgb(255, 255, 255);
        background-color: #007bff;
        font-size: 16px;
        padding: 2px 5px;

        .highlight_icon {
            height: 17px;
            padding: 6px;
            color: #fff;
            cursor: pointer;

            :hover {
                color: #0a58ca;
            }
        }

    }

    .row_content {
        width: 100%;
        display: inline-block;
    }
}

.colresizer {
    height: 75px;

    &__col {
        height: 75px;
        padding-left: 2px;
        padding-right: 2px;

        &__content {
            border: 1px solid;
            background-color: var(--bs-gray-200);
            width: 100%;
            height: 100%;
            display: inline-grid;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }

    .gutter-horizontal {
        height: 75px;
    }

    div {
        float: left;
    }
}

.gutter {
    background-color: #eee;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
}