#top-panel {
    .metadata {
        margin-top: 8px;
        margin-left: 10px;
        
        .content {
            margin-left: 10px;
        }
    }

}