.preview {
    width: 100%;
    height: 720px;

    &__loading {
        position: absolute;
        font-size: 50px;
        top: 300px;
        // z-index: 1;
    }

    &__mobile {
        display: flex;
        justify-content: center;
        position: relative;

        &__icon {
            position: absolute;
            width: 638px;
            height: 707px;
        }
        &__content {
            position: absolute;
            top: 65px;
            height: 571px;
            width: 330px;
            margin-left: 5px;
            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__desktop {
        display: flex;
        justify-content: center;
        position: relative;

        &__content {
            position: absolute;
            top: 65px;
            height: 571px;
            width: 330px;
            margin-left: 5px;
            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }

}
