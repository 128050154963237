
#vvveb-builder #canvas {
    // margin-right: 15vw;
    // margin-left: 15vw;
    margin-right: var(--builder-right-panel-width);
    margin-left: var(--builder-left-panel-width);
    /* width: 100%;
     height: 100%;
     width: calc(100vw - 600px);
     height: calc(100vh - 85px);
     top: 50px; */
    width: calc(100vw - var(--builder-left-panel-width) - var(--builder-right-panel-width) - var(--builder-canvas-margin));
    height: calc(100vh - var(--builder-header-top-height) - var(--builder-bottom-panel-height));
    top: var(--builder-header-top-height);
    position: fixed;
    transition: width .35s, left .35s;
    overflow-y: auto;
    // padding-top: 27px;
    // padding-bottom: 5px;
 }


 #vvveb-builder #canvas #body {
    padding-top: 27px;
 }