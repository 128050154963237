.text_container{
    position: relative;

    &__toolbar {
        position: absolute;
        top: -118px;
        // bottom: -120px;
        left: 0px;
        min-width: 450px;
        z-index: 200000;
        
        &__bottom {
            top: unset !important;
            bottom: -118px;
        }
        
        &__right {
            left: unset !important;
            right: 0px;
        }
    }
    
    &__text_content {
        text-align: left;
        color: #000;
    
        p {
            padding: 0px;
            margin: 0px;
        }
        
        ul {
            margin-top: 0px;
            margin-bottom: 0px;
            padding-inline-start: 30px;
        }

    }
}
