.link_property_label {
    display: flex;
    align-items: center;
    font-weight: 500;
}

.link_subproperty_label {
    display: block;
    width: 100%;
    padding: 0.4rem 0.8rem 0.4rem 0.8rem;
    -moz-padding-start: calc(.5rem - 3px);
    font-size: 12px;
    font-weight: 500;
    color: #212529;
    border: 1px solid #ced4da;
    border-radius: .25rem 0rem 0rem .25rem;
  }
  

.button_container {
    display: inline-block;
    width: 100%;

    &__toolbar {
        position: absolute;
        top: -78px;
        // bottom: -80px;
        left: 0px;
        min-width: 350px;

        &__bottom {
            top: unset !important;
            bottom: -78px;
        }
        
        &__right {
            left: unset !important;
            right: 0px;
        }
    }

    &__inner {
        display: inline-block;

        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}


