
.body_container {
    position: relative;
    background-color: #fff;

    &__sibling{
        position: absolute;
        width: 100%;
        height: 100%;
        &:hover {
            box-shadow: 0 0 0 3px #0198fa inset;
            // outline: 3px solid #0198fa;
        }
        &.hover {
            box-shadow: 0 0 0 3px #0198fa inset;
            // outline: 3px solid #0198fa;
        }
    }
}