.hr_table {
    table-layout: fixed;
    vertical-align: top;
    border-spacing: 0;
    border-collapse: collapse;
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
    min-width: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

.hr_container {
    width: 100%;
}

.hr_ie_container {
    border-top: 0px solid transparent;
    border-left: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 0px solid transparent;
    /* padding-top:5px; 
    padding-bottom:5px; 
    padding-right: 0px; 
    padding-left: 0px; */
}

.hr_cell {
    word-break: break-word;
    vertical-align: top;
    /* min-width: 100%; */
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    padding-top: 5px;
    padding-right: 0px;
    padding-bottom: 5px;
    padding-left: 0px;
    &__content {
        table-layout: fixed;
        vertical-align: top;
        border-spacing: 0;
        border-collapse: collapse;
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
        border-top: 1px solid #666666;
        height: 0px;
        /* width: 100%; */
    }
}

