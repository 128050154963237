.customhtml {
    &__container {
        pointer-events: none;
    }

    &__editor_section {
        .section{ 
            padding: 0px !important;
        }
    }
}


