.propertywrapper {
    &__title {
        font-size: 11px;
        font-weight: 600;
        height: auto;
        margin: 0;
        width: 100%;
        line-height: 32px;
        text-align: left;
        padding: 0.3rem 0.8rem;
        color: #007bff;
        cursor: pointer;
        position: relative;
        border-top: 1px solid #ddd;
        &__arrow {
            padding: .3rem .7rem;
            cursor: pointer;
            position: absolute;
            // font-family: Line Awesome Free;
            font-weight: 900;
            top: 0;
            right: 10px
        }
    }

    &__content {
        height: 100%;
        opacity: 1;
        padding: 0 14px 10px 14px;
        transition: height, opacity 0.5s;
    }
    
    .form-label {
        display: block;
        font-weight: 500;
    }
    
    // .form-control {
    //     margin-bottom: 10px;
    // }

}

.arrow_open {
    transform: rotate(0deg);
    transition: transform 0.4s; 
}

.arrow_close {
    transform: rotate(90deg);
    transition: transform 0.4s; 
}

/* .header_check:checked+div.section, li.header>input.header_check:checked+ol {
   opacity: 1;
   padding: 0 1rem;
   height: auto
} */

/* .header>ol, div.section {
   height: 0;
   opacity: 0;
   transition: height, opacity .5s
} */