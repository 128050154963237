.color_picker {
    position: relative;
    display: inline-flex;
    
    &__control {
        cursor: pointer;
        height: 31px;
        width: 27px;
        border: 2px solid gray;
        margin-right: 6px;
        border-radius: 3px;
    }
}