.image_picker .responsive {
    position: relative;
    margin: 0 6px;
    float: left;
    opacity: 1;
}
.image_picker .responsive .thumbnail {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 150px;
    opacity: 1;
    margin: 0;
}
.image_picker .responsive .thumbnail:hover {
    box-shadow: 0 0 2px 1px var(--bs-blue);
}
.image_picker .responsive .selected {
    background-color: var(--bs-blue);
}
.image_picker .responsive:hover {
    cursor: pointer;
}
.image_picker .responsive .checked .delete {
    display: none;
}
.responsive .delete {
    display: none;
}

.image_picker .selected {
    opacity: 0.7;
}
.image_picker .checked {
    display: inline-block;
    transition: 0.5s ease;
    position: absolute;
    bottom: 15px;
    right: 10px;
    background: var(--bs-success);
    border-radius: 50%;
    height: 35px;
    width: 35px;
    font-size: 24px;
    text-align: center;
    color: white;
}

.responsive:hover .delete {
    display: unset;
    position: absolute;
    transition: 0.5s ease;
    top: 15px;
    right: 10px;
    background: var(--bs-danger);
    border-radius: 50%;
    color: white;
    height: 35px;
    width: 35px;
    font-size: 24px;
    text-align: center;
}

.image_picker .clear {
    clear: both;
}
