/* div:empty {
    min-height: 50px;
    background: rgba(222,242,250,0.85);
    outline: 1px dashed #2faade;
  } */


/* div:empty:before {
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    vertical-align: middle;
    text-align: center;
    content: attr(data-label);;
  } */

*[selectable] {
    position: relative;
}

.unselectable {
    /* For Opera and <= IE9, we need to add unselectable="on" attribute onto each element */
    /* Check this site for more details: http://help.dottoro.com/lhwdpnva.php */
    -moz-user-select: none; /* These user-select properties are inheritable, used to prevent text selection */
    -webkit-user-select: none;
    -ms-user-select: none; /* From IE10 only */
    user-select: none; /* Not valid CSS yet, as of July 2012 */

    -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
    /* user-drag: none; */
}

.component_content {
    width: 100%;
}

.component_container {
    width: 100%;
    position: relative;
    /* display: inline-block;
    box-sizing: border-box; */
}

.canvas {
    overflow-y: auto;
}

.col {
    position: relative;
}




/* *************** START IMAGE *************** */

.image__container {
    width: 100%;
    padding: 0px;
}

.image__content {
    max-width: 100%;
}


/* *************** END IMAGE *************** */


/* *************** START HR *************** */

.hr__table {
    table-layout: fixed;
    vertical-align: top;
    border-spacing: 0;
    border-collapse: collapse;
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
    min-width: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

.hr__container {
    width: 100%;
}

.hr__ie__container {
    border-top: 0px solid transparent;
    border-left: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 0px solid transparent;
    /* padding-top:5px; 
  padding-bottom:5px; 
  padding-right: 0px; 
  padding-left: 0px; */
}

.hr__cell {
    word-break: break-word;
    vertical-align: top;
    /* min-width: 100%; */
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    padding-top: 5px;
    padding-right: 0px;
    padding-bottom: 5px;
    padding-left: 0px;
}

.hr__cell_content {
    table-layout: fixed;
    vertical-align: top;
    border-spacing: 0;
    border-collapse: collapse;
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
    border-top: 1px solid #666666;
    height: 0px;
    /* width: 100%; */
}


/* *************** END HR *************** */