.thumbnail {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.image_picker {
    width: 100%;
    max-height: 500px;
    height: 100%;
    overflow-y: auto;
}
