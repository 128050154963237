
.size_unity {
  display: block;
  width: 100%;
  padding: 0.4rem 0.8rem 0.4rem 0.8rem;
  -moz-padding-start: calc(.5rem - 3px);
  font-size: 1rem;
  color: #212529;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}
