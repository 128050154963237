  .drag_container {
    position: absolute;
    pointer-events: none;
    border-top: 3px solid #007bff;
    z-index: 3;
    width: 100%;
    left: 0px;
    
    * {
      pointer-events: none;
    }
    
    &__text_container {
      position: relative;
      
      &__label {
        position: absolute;
        background-color: #007BFE;
        // padding: 4px 1px 4px 1px;
        border-radius: 5px;
        color: white;
        font-size: 12px;
        z-index: 1;
        top: -11px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 85px;
    }
  }
}

