.conditionblock {
    &__form {
        &__error {
            border-color: rgba(253, 41, 13, 0.418) !important;
            box-shadow: 0 0 0 0.25rem rgba(253, 41, 13, 0.418) !important;
        }
    }
    &__alert {
        color: red;
        font-weight: 500;

        &__icon {
            font-size: 15px;
        }
    }
}