:root {
   --bs-blue: #0d6efd;
   --bs-indigo: #6610f2;
   --bs-purple: #6f42c1;
   --bs-pink: #d63384;
   --bs-red: #dc3545;
   --bs-orange: #fd7e14;
   --bs-yellow: #ffc107;
   --bs-green: #198754;
   --bs-teal: #20c997;
   --bs-cyan: #0dcaf0;
   --bs-white: #fff;
   --bs-gray: #6c757d;
   --bs-gray-dark: #343a40;
   --bs-gray-100: #f8f9fa;
   --bs-gray-200: #e9ecef;
   --bs-gray-300: #dee2e6;
   --bs-gray-400: #ced4da;
   --bs-gray-500: #adb5bd;
   --bs-gray-600: #6c757d;
   --bs-gray-700: #495057;
   --bs-gray-800: #343a40;
   --bs-gray-900: #212529;
   --bs-primary: #0d6efd;
   --bs-secondary: #6c757d;
   --bs-success: #198754;
   --bs-info: #0dcaf0;
   --bs-warning: #ffc107;
   --bs-danger: #dc3545;
   --bs-light: #fafafa;
   --bs-dark: #212529;
   --bs-primary-rgb: 13, 110, 253;
   --bs-secondary-rgb: 108, 117, 125;
   --bs-success-rgb: 25, 135, 84;
   --bs-info-rgb: 13, 202, 240;
   --bs-warning-rgb: 255, 193, 7;
   --bs-danger-rgb: 220, 53, 69;
   --bs-light-rgb: 250, 250, 250;
   --bs-dark-rgb: 33, 37, 41;
   --bs-white-rgb: 255, 255, 255;
   --bs-black-rgb: 0, 0, 0;
   --bs-body-color-rgb: 33, 37, 41;
   --bs-body-bg-rgb: 255, 255, 255;
   --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
   --bs-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, 0.15), hsla(0, 0%, 100%, 0));
   --bs-body-font-family: var(--bs-font-sans-serif);
   --bs-body-font-size: 1rem;
   --bs-body-font-weight: 400;
   --bs-body-line-height: 1.5;
   --bs-body-color: #212529;
   --bs-body-bg: #fff;
   --bs-gutter-x: 1.5rem;
   --bs-gutter-y: 0;
}
code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
   font-size: 14px
}

body {
   font-family: "Helvetica Neue", Helvetica, -apple-system, Arial, "Lucida Grande", system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

i.la {
   font-style: normal
}

@font-face {
   font-family: Line Awesome Brands;
   font-style: normal;
   font-weight: 400;
   font-display: auto;
   src: url("../../fonts/line-awesome/la-brands-400.eot");
   src: url("../../fonts/line-awesome/la-brands-400.eot?#iefix") format("embedded-opentype"), url("../../fonts/line-awesome/la-brands-400.woff2") format("woff2"), url("../../fonts/line-awesome/la-brands-400.woff") format("woff"), url("../../fonts/line-awesome/la-brands-400.ttf") format("truetype"), url("../../fonts/line-awesome/la-brands-400.svg#lineawesome") format("svg");
}

@font-face {
   font-family: Line Awesome Free;
   font-style: normal;
   font-weight: 400;
   font-display: auto;
   src: url("../../fonts/line-awesome/la-regular-400.eot");
   src: url("../../fonts/line-awesome/la-regular-400.eot?#iefix") format("embedded-opentype"), url("../../fonts/line-awesome/la-regular-400.woff2") format("woff2"), url("../../fonts/line-awesome/la-regular-400.woff") format("woff"), url("../../fonts/line-awesome/la-regular-400.ttf") format("truetype"), url("../../fonts/line-awesome/la-regular-400.svg#lineawesome") format("svg");
}

.la {
   font-weight: 400
}

@font-face {
   font-family: Line Awesome Free;
   font-style: normal;
   font-weight: 900;
   font-display: auto;
   src: url("../../fonts/line-awesome/la-solid-900.eot");
   src: url("../../fonts/line-awesome/la-solid-900.eot?#iefix") format("embedded-opentype"), url("../../fonts/line-awesome/la-solid-900.woff2") format("woff2"), url("../../fonts/line-awesome/la-solid-900.woff") format("woff"), url("../../fonts/line-awesome/la-solid-900.ttf") format("truetype"), url("../../fonts/line-awesome/la-solid-900.svg#lineawesome") format("svg");
}

.la {
   font-family: Line Awesome Free;
   font-weight: 900
}

.la-lg {
   font-size: 1.33333em;
   line-height: .75em;
   vertical-align: -.0667em
}

.la-spin {
   -webkit-animation: la-spin 2s linear infinite;
   animation: la-spin 2s linear infinite
}

@-webkit-keyframes la-spin {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
   }

   to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn)
   }
}

@keyframes la-spin {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
   }

   to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn)
   }
}

.la-flip-horizontal {
   -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
   -webkit-transform: scaleX(-1);
   transform: scaleX(-1)
}

:root .la-flip-horizontal {
   -webkit-filter: none;
   filter: none
}

/*!
   * Bootstrap v5.1.3 (https://getbootstrap.com/)
   * Copyright 2011-2021 The Bootstrap Authors
   * Copyright 2011-2021 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */


*, :after, :before {
   box-sizing: border-box
}

@media(prefers-reduced-motion:no-preference) {
   :root {
      scroll-behavior: smooth
   }
}

body {
   margin: 0;
   font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   font-family: var(--bs-body-font-family);
   font-size: 1rem;
   font-size: var(--bs-body-font-size);
   font-weight: 400;
   font-weight: var(--bs-body-font-weight);
   line-height: 1.5;
   line-height: var(--bs-body-line-height);
   color: #212529;
   color: var(--bs-body-color);
   text-align: var(--bs-body-text-align);
   background-color: #fff;
   background-color: var(--bs-body-bg);
   -webkit-text-size-adjust: 100%;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
   margin-top: 0;
   margin-bottom: .5rem;
   font-weight: 500;
   line-height: 1.2
}

.h1, h1 {
   font-size: calc(1.375rem + 1.5vw)
}

@media(min-width:1200px) {
   .h1, h1 {
      font-size: 2.5rem
   }
}

.h2, h2 {
   font-size: calc(1.325rem + .9vw)
}

@media(min-width:1200px) {
   .h2, h2 {
      font-size: 2rem
   }
}

.h3, h3 {
   font-size: calc(1.3rem + .6vw)
}

@media(min-width:1200px) {
   .h3, h3 {
      font-size: 1.75rem
   }
}

.h4, h4 {
   font-size: calc(1.275rem + .3vw)
}

@media(min-width:1200px) {
   .h4, h4 {
      font-size: 1.5rem
   }
}

.h5, h5 {
   font-size: 1.25rem
}

.h6, h6 {
   font-size: 1rem
}

abbr[title] {
   -webkit-text-decoration: underline dotted;
   text-decoration: underline dotted;
   cursor: help;
   -webkit-text-decoration-skip-ink: none;
   text-decoration-skip-ink: none
}

address {
   margin-bottom: 1rem;
   font-style: normal;
   line-height: inherit
}

ol, ul {
   padding-left: 2rem
}

dl, ol, ul {
   margin-top: 0;
   margin-bottom: 1rem
}

ol ol, ol ul, ul ol, ul ul {
   margin-bottom: 0
}

dt {
   font-weight: 700
}

dd {
   margin-bottom: .5rem;
   margin-left: 0
}

blockquote {
   margin: 0 0 1rem
}

b, strong {
   font-weight: bolder
}

.small, small {
   font-size: .875em
}

.mark, mark {
   padding: .2em;
   background-color: #fcf8e3
}

sub, sup {
   position: relative;
   font-size: .75em;
   line-height: 0;
   vertical-align: baseline
}

sub {
   bottom: -.25em
}

sup {
   top: -.5em
}

a {
   color: #0d6efd;
   text-decoration: none
}

a:hover {
   color: #0a58ca
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
   color: inherit;
   text-decoration: none
}

code, kbd, pre, samp {
   font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
   font-family: var(--bs-font-monospace);
   font-size: 1em;
   direction: ltr;
   unicode-bidi: bidi-override
}

pre {
   display: block;
   margin-top: 0;
   margin-bottom: 1rem;
   overflow: auto;
   font-size: .875em
}

pre code {
   font-size: inherit;
   color: inherit;
   word-break: normal
}

code {
   font-size: .875em;
   color: #d63384;
   word-wrap: break-word
}

a>code {
   color: inherit
}

kbd {
   padding: .2rem .4rem;
   font-size: .875em;
   color: #fff;
   background-color: #212529;
   border-radius: .2rem
}

kbd kbd {
   padding: 0;
   font-size: 1em;
   font-weight: 700
}

figure {
   margin: 0 0 1rem
}

img, svg {
   vertical-align: middle
}

table {
   caption-side: bottom;
   border-collapse: collapse
}

caption {
   padding-top: .5rem;
   padding-bottom: .5rem;
   color: #6c757d;
   text-align: left
}

th {
   text-align: inherit;
   text-align: -webkit-match-parent
}

tbody, td, tfoot, th, thead, tr {
   border: 0 solid;
   border-color: inherit
}

label {
   display: inline-block
}

button {
   border-radius: 0
}

button:focus:not(:focus-visible) {
   outline: 0
}

button, input, optgroup, select, textarea {
   margin: 0;
   font-family: inherit;
   font-size: inherit;
   line-height: inherit
}

button, select {
   text-transform: none
}

[role=button] {
   cursor: pointer
}

select {
   word-wrap: normal
}

select:disabled {
   opacity: 1
}

[list]::-webkit-calendar-picker-indicator {
   display: none
}

[type=button], [type=reset], [type=submit], button {
   -webkit-appearance: button
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
   cursor: pointer
}

::-moz-focus-inner {
   padding: 0;
   border-style: none
}

textarea {
   resize: vertical
}

fieldset {
   min-width: 0;
   padding: 0;
   margin: 0;
   border: 0
}

legend {
   float: left;
   width: 100%;
   padding: 0;
   margin-bottom: .5rem;
   font-size: calc(1.275rem + .3vw);
   line-height: inherit
}

@media(min-width:1200px) {
   legend {
      font-size: 1.5rem
   }
}

legend+* {
   clear: left
}

::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-year-field {
   padding: 0
}

::-webkit-inner-spin-button {
   height: auto
}

[type=search] {
   outline-offset: -2px;
   -webkit-appearance: textfield
}

::-webkit-search-decoration {
   -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
   padding: 0
}

::file-selector-button {
   font: inherit
}

::-webkit-file-upload-button {
   font: inherit;
   -webkit-appearance: button
}

output {
   display: inline-block
}

iframe {
   border: 0
}

summary {
   display: list-item;
   cursor: pointer
}

progress {
   vertical-align: baseline
}

[hidden] {
   display: none !important
}

.blockquote {
   margin-bottom: 1rem;
   font-size: 1.25rem
}

.blockquote>:last-child {
   margin-bottom: 0
}

.figure {
   display: inline-block
}

.container {
   width: 100%;
   padding-right: .75rem;
   padding-right: var(--bs-gutter-x, .75rem);
   padding-left: .75rem;
   padding-left: var(--bs-gutter-x, .75rem);
   margin-right: auto;
   margin-left: auto;
   min-width: 150px
}

@media(min-width:576px) {
   .container {
      max-width: 540px
   }
}

@media(min-width:768px) {
   .container {
      max-width: 720px
   }
}

@media(min-width:992px) {
   .container {
      max-width: 960px
   }
}

@media(min-width:1200px) {
   .container {
      max-width: 1140px
   }
}

@media(min-width:1400px) {
   .container {
      max-width: 1320px
   }
}


.row {
   display: flex;
   flex-wrap: wrap;
   /* margin-top: calc(var(--bs-gutter-y)*-1) */
}

.row>* {
   flex-shrink: 0;
   width: 100%;
   max-width: 100%;
   margin-top: var(--bs-gutter-y)
}

.col {
   flex: 1 0
}

.col-2 {
   flex: 0 0 auto;
   width: 16.66667%
}

.col-3 {
   flex: 0 0 auto;
   width: 25%
}

.col-4 {
   flex: 0 0 auto;
   width: 33.33333%
}

.col-6 {
   flex: 0 0 auto;
   width: 50%
}

.col-12 {
   flex: 0 0 auto;
   width: 100%
}

.offset-1 {
   margin-left: 8.33333%
}

@media(min-width:576px) {
   .col-sm-6 {
      flex: 0 0 auto;
      width: 50%
   }

   .col-sm-12 {
      flex: 0 0 auto;
      width: 100%
   }
}

@media (min-width: 992px) {
   .col-lg {
     flex: 1 0 0%; }
   .row-cols-lg-auto > * {
     flex: 0 0 auto;
     width: auto; }
   .row-cols-lg-1 > * {
     flex: 0 0 auto;
     width: 100%; }
   .row-cols-lg-2 > * {
     flex: 0 0 auto;
     width: 50%; }
   .row-cols-lg-3 > * {
     flex: 0 0 auto;
     width: 33.33333%; }
   .row-cols-lg-4 > * {
     flex: 0 0 auto;
     width: 25%; }
   .row-cols-lg-5 > * {
     flex: 0 0 auto;
     width: 20%; }
   .row-cols-lg-6 > * {
     flex: 0 0 auto;
     width: 16.66667%; }
   .col-lg-auto {
     flex: 0 0 auto;
     width: auto; }
   .col-lg-1 {
     flex: 0 0 auto;
     width: 8.33333%; }
   .col-lg-2 {
     flex: 0 0 auto;
     width: 16.66667%; }
   .col-lg-3 {
     flex: 0 0 auto;
     width: 25%; }
   .col-lg-4 {
     flex: 0 0 auto;
     width: 33.33333%; }
   .col-lg-5 {
     flex: 0 0 auto;
     width: 41.66667%; }
   .col-lg-6 {
     flex: 0 0 auto;
     width: 50%; }
   .col-lg-7 {
     flex: 0 0 auto;
     width: 58.33333%; }
   .col-lg-8 {
     flex: 0 0 auto;
     width: 66.66667%; }
   .col-lg-9 {
     flex: 0 0 auto;
     width: 75%; }
   .col-lg-10 {
     flex: 0 0 auto;
     width: 83.33333%; }
   .col-lg-11 {
     flex: 0 0 auto;
     width: 91.66667%; }
   .col-lg-12 {
     flex: 0 0 auto;
     width: 100%; }
   .offset-lg-0 {
     margin-left: 0; }
   .offset-lg-1 {
     margin-left: 8.33333%; }
   .offset-lg-2 {
     margin-left: 16.66667%; }
   .offset-lg-3 {
     margin-left: 25%; }
   .offset-lg-4 {
     margin-left: 33.33333%; }
   .offset-lg-5 {
     margin-left: 41.66667%; }
   .offset-lg-6 {
     margin-left: 50%; }
   .offset-lg-7 {
     margin-left: 58.33333%; }
   .offset-lg-8 {
     margin-left: 66.66667%; }
   .offset-lg-9 {
     margin-left: 75%; }
   .offset-lg-10 {
     margin-left: 83.33333%; }
   .offset-lg-11 {
     margin-left: 91.66667%; }
   .g-lg-0,
   .gx-lg-0 {
     --bs-gutter-x: 0; }
   .g-lg-0,
   .gy-lg-0 {
     --bs-gutter-y: 0; }
   .g-lg-1,
   .gx-lg-1 {
     --bs-gutter-x: 0.25rem; }
   .g-lg-1,
   .gy-lg-1 {
     --bs-gutter-y: 0.25rem; }
   .g-lg-2,
   .gx-lg-2 {
     --bs-gutter-x: 0.5rem; }
   .g-lg-2,
   .gy-lg-2 {
     --bs-gutter-y: 0.5rem; }
   .g-lg-3,
   .gx-lg-3 {
     --bs-gutter-x: 1rem; }
   .g-lg-3,
   .gy-lg-3 {
     --bs-gutter-y: 1rem; }
   .g-lg-4,
   .gx-lg-4 {
     --bs-gutter-x: 1.5rem; }
   .g-lg-4,
   .gy-lg-4 {
     --bs-gutter-y: 1.5rem; }
   .g-lg-5,
   .gx-lg-5 {
     --bs-gutter-x: 3rem; }
   .g-lg-5,
   .gy-lg-5 {
     --bs-gutter-y: 3rem; } }
 
 

.table {
   --bs-table-bg: transparent;
   --bs-table-accent-bg: transparent;
   --bs-table-striped-color: #212529;
   --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
   --bs-table-active-color: #212529;
   --bs-table-active-bg: rgba(0, 0, 0, 0.1);
   --bs-table-hover-color: #212529;
   --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
   width: 100%;
   margin-bottom: 1rem;
   color: #212529;
   vertical-align: top;
   border-color: #dee2e6
}

.table>:not(caption)>*>* {
   padding: .5rem;
   background-color: var(--bs-table-bg);
   border-bottom-width: 1px;
   box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tbody {
   vertical-align: inherit
}

.table>thead {
   vertical-align: bottom
}

.table>:not(:first-child) {
   border-top: 2px solid
}

.form-label {
   margin-bottom: .5rem
}

.form-control {
   display: block;
   width: 100%;
   padding: .4rem .5rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #212529;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #ced4da;
   -webkit-appearance: none;
   appearance: none;
   border-radius: .25rem;
   transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
   .form-control {
      transition: none
   }
}

.form-control[type=file] {
   overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
   cursor: pointer
}

.form-control:focus {
   color: #212529;
   background-color: #fff;
   border-color: #86b7fe;
   outline: 0;
   box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-control::-webkit-date-and-time-value {
   height: 1.5em
}

.form-control::-webkit-input-placeholder {
   color: #6c757d;
   opacity: 1
}

.form-control:-ms-input-placeholder {
   color: #6c757d;
   opacity: 1
}

.form-control::placeholder {
   color: #6c757d;
   opacity: 1
}

.form-control:disabled, .form-control[readonly] {
   background-color: #e9ecef;
   opacity: 1
}

.form-control::file-selector-button {
   padding: .4rem .5rem;
   margin: -.4rem -.5rem;
   -webkit-margin-end: .5rem;
   margin-inline-end: .5rem;
   color: #212529;
   background-color: #e9ecef;
   pointer-events: none;
   border: 0 solid;
   border-color: inherit;
   border-inline-end-width: 1px;
   border-radius: 0;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
   .form-control::file-selector-button {
      transition: none
   }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
   background-color: #dde0e3
}

.form-control::-webkit-file-upload-button {
   padding: .4rem .5rem;
   margin: -.4rem -.5rem;
   -webkit-margin-end: .5rem;
   margin-inline-end: .5rem;
   color: #212529;
   background-color: #e9ecef;
   pointer-events: none;
   border: 0 solid;
   border-color: inherit;
   border-inline-end-width: 1px;
   border-radius: 0;
   -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
   .form-control::-webkit-file-upload-button {
      -webkit-transition: none;
      transition: none
   }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
   background-color: #dde0e3
}

textarea.form-control {
   min-height: default
}

.form-select {
   display: block;
   width: 100%;
   padding: .4rem 1.5rem .4rem .5rem;
   -moz-padding-start: calc(.5rem - 3px);
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #212529;
   background-color: #fff;
   background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3E%3Cpath fill='none' stroke='%23aaa' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
   background-repeat: no-repeat;
   background-position: right .7rem center;
   background-size: 10px 12px;
   border: 1px solid #ced4da;
   border-radius: .25rem;
   transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   appearance: none
}

@media(prefers-reduced-motion:reduce) {
   .form-select {
      transition: none
   }
}

.form-select:focus {
   border-color: #86b7fe;
   outline: 0;
   box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
   padding-right: .5rem;
   background-image: none
}

.form-select:disabled {
   background-color: #e9ecef
}

.form-select:-moz-focusring {
   color: transparent;
   text-shadow: 0 0 0 #212529
}

.btn-check {
   position: absolute;
   clip: rect(0, 0, 0, 0);
   pointer-events: none
}

.btn-check:disabled+.btn, .btn-check[disabled]+.btn {
   pointer-events: none;
   -webkit-filter: none;
   filter: none;
   opacity: .65
}


.input-group {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   align-items: stretch;
   width: 100%
}

.input-group>.form-control, .input-group>.form-select {
   position: relative;
   flex: 1 1 auto;
   width: 1%;
   min-width: 0
}

.input-group>.form-control:focus, .input-group>.form-select:focus {
   z-index: 3
}

.input-group .btn {
   position: relative;
   z-index: 2
}

.input-group .btn:focus {
   z-index: 3
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
   margin-left: -1px;
   border-top-left-radius: 0;
   border-bottom-left-radius: 0
}

.btn {
   display: inline-block;
   font-weight: 400;
   line-height: 1.5;
   color: #212529;
   text-align: center;
   vertical-align: middle;
   cursor: pointer;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
   background-color: transparent;
   border: 1px solid transparent;
   padding: .4rem .5rem;
   font-size: 1rem;
   border-radius: .25rem;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
   .btn {
      transition: none
   }
}

.btn:hover {
   color: #212529
}

.btn-check:focus+.btn, .btn:focus {
   outline: 0;
   box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
   pointer-events: none;
   opacity: .65
}

.btn-primary {
   color: #fff;
   background-color: #0d6efd;
   border-color: #0d6efd
}

.btn-check:focus+.btn-primary, .btn-primary:focus, .btn-primary:hover {
   color: #fff;
   background-color: #0b5ed7;
   border-color: #0a58ca
}

.btn-check:focus+.btn-primary, .btn-primary:focus {
   box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5)
}

.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active {
   color: #fff;
   background-color: #0a58ca;
   border-color: #0a53be
}

.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus {
   box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5)
}

.btn-primary.disabled, .btn-primary:disabled {
   color: #fff;
   background-color: #0d6efd;
   border-color: #0d6efd
}

.btn-light {
   color: #000;
   background-color: #fafafa;
   border-color: #fafafa
}

.btn-check:focus+.btn-light, .btn-light:focus, .btn-light:hover {
   color: #000;
   background-color: #fbfbfb;
   border-color: #fbfbfb
}

.btn-check:focus+.btn-light, .btn-light:focus {
   box-shadow: 0 0 0 .25rem hsla(0, 0%, 83.5%, .5)
}

.btn-check:active+.btn-light, .btn-check:checked+.btn-light, .btn-light.active, .btn-light:active {
   color: #000;
   background-color: #fbfbfb;
   border-color: #fbfbfb
}

.btn-check:active+.btn-light:focus, .btn-check:checked+.btn-light:focus, .btn-light.active:focus, .btn-light:active:focus {
   box-shadow: 0 0 0 .25rem hsla(0, 0%, 83.5%, .5)
}

.btn-light.disabled, .btn-light:disabled {
   color: #000;
   background-color: #fafafa;
   border-color: #fafafa
}

.btn-outline-primary {
   color: #0d6efd;
   border-color: #0d6efd
}

.btn-outline-primary:hover {
   color: #fff;
   background-color: #0d6efd;
   border-color: #0d6efd
}

.btn-check:focus+.btn-outline-primary, .btn-outline-primary:focus {
   box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5)
}

.btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary:active {
   color: #fff;
   background-color: #0d6efd;
   border-color: #0d6efd
}

.btn-check:active+.btn-outline-primary:focus, .btn-check:checked+.btn-outline-primary:focus, .btn-outline-primary.active:focus, .btn-outline-primary:active:focus {
   box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5)
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
   color: #0d6efd;
   background-color: transparent
}

.btn-group-sm>.btn {
   padding: .25rem .5rem;
   font-size: .875rem;
   border-radius: .2rem
}

.fade {
   transition: opacity .15s linear
}

@media(prefers-reduced-motion:reduce) {
   .fade {
      transition: none
   }
}

.fade:not(.show) {
   opacity: 0
}

.collapse:not(.show) {
   display: none
}

.btn-group {
   position: relative;
   display: inline-flex;
   vertical-align: middle
}

.btn-group>.btn {
   position: relative;
   flex: 1 1 auto
}

.btn-group>.btn-check:checked+.btn, .btn-group>.btn-check:focus+.btn, .btn-group>.btn.active, .btn-group>.btn:active, .btn-group>.btn:focus, .btn-group>.btn:hover {
   z-index: 1
}

.btn-group>.btn-group:not(:first-child), .btn-group>.btn:not(:first-child) {
   margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:nth-child(n+3), .btn-group>:not(.btn-check)+.btn {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0
}

.nav {
   display: flex;
   flex-wrap: wrap;
   padding-left: 0;
   margin-bottom: 0;
   list-style: none
}

.nav-link {
   display: block;
   padding: .5rem 1rem;
   color: #0d6efd;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
   .nav-link {
      transition: none
   }
}

.nav-link:focus, .nav-link:hover {
   color: #0a58ca
}

.nav-link.disabled {
   color: #6c757d;
   pointer-events: none;
   cursor: default
}

.nav-tabs {
   border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-link {
   margin-bottom: -1px;
   background: none;
   border: 1px solid transparent;
   border-top-left-radius: .25rem;
   border-top-right-radius: .25rem
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
   border-color: #e9ecef #e9ecef #dee2e6;
   isolation: isolate
}

.nav-tabs .nav-link.disabled {
   color: #6c757d;
   background-color: transparent;
   border-color: transparent
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
   color: #495057;
   background-color: #fff;
   border-color: #dee2e6 #dee2e6 #fff
}

.nav-item {
   cursor: pointer;
}

.nav-fill .nav-item, .nav-fill>.nav-link {
   flex: 1 1 auto;
   text-align: center
}

.nav-fill .nav-item .nav-link {
   width: 100%
}

.tab-content>.tab-pane {
   display: none
}

.tab-content>.active {
   display: block
}

.card {
   position: relative;
   display: flex;
   flex-direction: column;
   min-width: 0;
   word-wrap: break-word;
   background-color: #fff;
   background-clip: border-box;
   border: 1px solid rgba(0, 0, 0, .125);
   border-radius: .25rem
}

.card>hr {
   margin-right: 0;
   margin-left: 0
}

.badge {
   display: inline-block;
   padding: .35em .65em;
   font-size: .75em;
   font-weight: 700;
   line-height: 1;
   color: #fff;
   text-align: center;
   white-space: nowrap;
   vertical-align: baseline;
   border-radius: .25rem
}

.badge:empty {
   display: none
}

.btn .badge {
   position: relative;
   top: -1px
}

.alert {
   position: relative;
   padding: 1rem;
   margin-bottom: 1rem;
   border: 1px solid transparent;
   border-radius: .25rem
}

.alert-dismissible {
   padding-right: 3rem
}

@-webkit-keyframes progress-bar-stripes {
   0% {
      background-position-x: 1rem
   }
}

@keyframes progress-bar-stripes {
   0% {
      background-position-x: 1rem
   }
}

.progress {
   height: 1rem;
   font-size: .75rem;
   background-color: #e9ecef;
   border-radius: .25rem
}

.progress {
   display: flex;
   overflow: hidden
}

.modal {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1055;
   display: none;
   width: 100%;
   height: 100%;
   overflow-x: hidden;
   overflow-y: auto;
   outline: 0
}

.tooltip {
   position: absolute;
   z-index: 1080;
   display: block;
   margin: 0;
   font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   font-family: var(--bs-font-sans-serif);
   font-style: normal;
   font-weight: 400;
   line-height: 1.5;
   text-align: left;
   text-align: start;
   text-decoration: none;
   text-shadow: none;
   text-transform: none;
   letter-spacing: normal;
   word-break: normal;
   word-spacing: normal;
   white-space: normal;
   line-break: auto;
   font-size: .875rem;
   word-wrap: break-word;
   opacity: 0
}

.tooltip.show {
   opacity: .9
}

@-webkit-keyframes spinner-border {
   to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn)
   }
}

@keyframes spinner-border {
   to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn)
   }
}

@-webkit-keyframes spinner-grow {
   0% {
      -webkit-transform: scale(0);
      transform: scale(0)
   }

   50% {
      opacity: 1;
      -webkit-transform: none;
      transform: none
   }
}

@keyframes spinner-grow {
   0% {
      -webkit-transform: scale(0);
      transform: scale(0)
   }

   50% {
      opacity: 1;
      -webkit-transform: none;
      transform: none
   }
}

.placeholder {
   display: inline-block;
   min-height: 1em;
   vertical-align: middle;
   cursor: wait;
   background-color: currentColor;
   opacity: .5
}

.placeholder.btn:before {
   display: inline-block;
   content: ""
}

@-webkit-keyframes placeholder-glow {
   50% {
      opacity: .2
   }
}

@keyframes placeholder-glow {
   50% {
      opacity: .2
   }
}

@-webkit-keyframes placeholder-wave {
   to {
      -webkit-mask-position: -200% 0;
      mask-position: -200% 0
   }
}

@keyframes placeholder-wave {
   to {
      -webkit-mask-position: -200% 0;
      mask-position: -200% 0
   }
}

.clearfix:after, .drag-elements-sidepane ul>li ol:after {
   display: block;
   clear: both;
   content: ""
}

.vr {
   display: inline-block;
   align-self: stretch;
   width: 1px;
   min-height: 1em;
   background-color: currentColor;
   opacity: .25
}

.float-start {
   float: left !important
}

.float-end {
   float: right !important
}

.shadow {
   box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.border {
   border: 1px solid #dee2e6 !important
}

.m-3 {
   margin: 3px !important
}

.m-4 {
   margin: 4px !important
}
.m-5 {
   margin: 5px !important
}

.me-3 {
   margin-right: 3px !important
}
.mt-3 {
   margin-top: 3px !important
}
.mt-4 {
   margin-top: 4px !important; }

.mt-5 {
   margin-top: 5px !important; }

.mb-3 {
   margin-bottom: 3px !important
}

.mb-4 {
   margin-bottom: 4px !important
}

.mb-6 {
   margin-bottom: 6px !important
}

.mb-12 {
   margin-bottom: 12px !important
}

.p-1 {
   padding: 1px !important
}

.p-2 {
   padding: 2px !important
}

.p-3 {
   padding: 3px !important
}

.p-4 {
   padding: 4px !important
}

.p-5 {
   padding: 5px !important
}

.rounded {
   border-radius: .25rem !important
}

.visible {
   visibility: visible !important
}

.invisible {
   visibility: hidden !important
}

@media(min-width:576px) {
   .d-sm-none {
      display: none !important
   }
}

@media(min-width:992px) {
   .d-lg-none {
      display: none !important
   }
}

.tree {
   width: 100%
}

.tree ol {
   padding: .3rem 0 0 2rem;
   margin: 0;
   min-width: 30vw;
   font-size: 12px;
   font-weight: 500;
   color: #555
}

.tree ol li {
   position: relative;
   margin-left: -15px;
   margin-bottom: .3rem;
   list-style: none;
   color: #555;
   overflow: hidden
}

.tree ol li.active>a>span, .tree ol li.active>label>span, .tree ol li:hover {
   color: #007bff
}

.tree ol li.file>label {
   background: url(../../libs/builder/icons/file.svg) 24px 0px no-repeat;
   background-size: 21px 21px;
   cursor: pointer;
   display: block;
   padding-left: 56px;
   margin: 0;
   font-size: 12px;
   font-weight: 500;
   line-height: 28px
}

.tree ol li.file>label:hover {
   color: #0d6efd
}

.tree ol li input {
   position: absolute;
   left: 0;
   margin-left: 0;
   opacity: 0;
   z-index: 2;
   cursor: pointer;
   height: 1em;
   width: 1em;
   top: 0
}

.tree ol li input+ol {
   background: url(../../libs/builder/icons/arrow-right.svg) 5px 0.3rem no-repeat;
   background-size: 10px 10px;
   margin: -1.8rem 0 0;
   padding: 2rem 0 0 2rem;
   height: 0
}

.tree ol li input+ol>li {
   display: none;
   margin-left: -14px !important;
   padding-left: 1px
}

.tree ol li label {
   background: url(../../libs/builder/icons/folder.svg) 24px 1px no-repeat;
   background-size: 24px 24px;
   cursor: pointer;
   display: block;
   padding-left: 56px;
   margin: 0;
   font-size: 12px;
   font-weight: 500;
   line-height: 28px
}

.tree ol li input:checked+ol {
   background: url(../../libs/builder/icons/arrow-down.svg) 5px 0.1rem no-repeat;
   background-size: 10px 10px;
   margin: -1.8em 0 0;
   padding: 2rem 0 0 2rem;
   height: auto
}

.tree ol li input:checked+ol>li {
   display: block;
   margin: 0 0 .5em
}

.tree ol li input:checked+ol>li:last-child {
   margin: 0 0 .7em
}

body {
   background: #eee;
   --builder-filemanager-height: 250px;
   --builder-canvas-margin: 0px;
   --builder-header-top-height: 50px;
   --builder-bottom-panel-height: 35px;
   --drag-items-tabs-height: 40px;
   -webkit-font-smoothing: subpixel-antialiased
}

@media(min-width:576px) {
   body {
      --builder-left-panel-width: 0;
      --builder-right-panel-width: 25vw;
      --builder-component-list-element-width: 90%
   }
}

@media(min-width:768px) {
   body {
      --builder-left-panel-width: 25vw;
      --builder-right-panel-width: 25vw;
      --builder-component-list-element-width: 90%
   }
}

@media(min-width:992px) {
   body {
      --builder-left-panel-width: 250px;
      --builder-right-panel-width: 250px;
      --builder-component-list-element-width: 44%
   }
}

@media(min-width:1200px) {
   body {
      --builder-left-panel-width: 300px;
      --builder-right-panel-width: 300px;
      --builder-component-list-element-width: 44%
   }
}

@media(min-width:1600px) {
   body {
      --builder-left-panel-width: 350px;
      --builder-right-panel-width: 350px;
      --builder-component-list-element-width: 44%
   }
}

@media(min-width:2000px) {
   body {
      --builder-left-panel-width: 400px;
      --builder-right-panel-width: 400px;
      --builder-component-list-element-width: 28%
   }
}

#vvveb-builder {
   display: block;
   flex: 1 1 auto;
   display: flex;
   flex-direction: row;
   height: 100%
}

#vvveb-builder #right-panel {
   border: 0 solid #ddd;
   background-color: #fff;
   height: 100%;
   position: fixed;
   top: 50px;
   /* z-index: 10 */
}

#vvveb-builder #right-panel {
   right: 0;
   border-left-width: 1px;
   float: right;
   transition: margin-right .1s linear;
   -moz-transition: margin-right .1s linear;
   -webkit-transition: margin-right .1s linear;
   background: linear-gradient(to right, #fff 85%, #fafbfc);
   box-shadow: inset 1px -2px 2px #f3f3f3;
   width: 15vw;
   width: var(--builder-right-panel-width)
}

#vvveb-builder #right-panel .component-properties {
   width: var(--builder-right-panel-width)
}

#vvveb-builder #right-panel .nav-link {
   color: #333;
   border-top: 2px solid transparent
}

#vvveb-builder #right-panel .nav-link.active {
   border-top: 2px solid #0d6efd;
   color: #0d6efd
}

#vvveb-builder #top-panel {
   height: 50px;
   padding-top: .3rem;
   border-bottom: 1px solid #ccc;
   text-align: center;
   background-color: #fafafa;
   overflow: hidden;
   width: 100%
}

#vvveb-builder #top-panel .btn-group .btn {
   padding: .25rem .5rem;
   font-size: 1.25rem
}

#vvveb-builder #top-panel .btn-group .btn.btn-primary {
   padding: 0 1rem;
   margin-top: .2rem
}

#vvveb-builder #top-panel .btn-group .btn.btn-primary i {
   font-size: 21px
}

#vvveb-builder #top-panel .btn-group .btn span {
   font-size: 14px
}

#vvveb-builder #top-panel .btn-group.responsive-btns .btn {
   font-size: 1.6rem;
   padding: 0 .5rem
}

#vvveb-builder .drag-elements {
   height: 100%;
   padding-top: 0
}

#vvveb-builder .drag-elements>.header {
   height: 100%
}

#vvveb-builder .drag-elements>.header .nav {
   background: #f9f9f9
}

#vvveb-builder .drag-elements>.header .search {
   position: relative
}

#vvveb-builder .drag-elements>.header .search .form-control {
   border-color: #fff #fff #eee;
   border-radius: 0;
   box-shadow: none
}

#vvveb-builder .drag-elements>.header .search .form-control:focus {
   border-color: #80bdff
}

#vvveb-builder .drag-elements>.header .search .form-control::-webkit-input-placeholder {
   font-size: .75rem
}

#vvveb-builder .drag-elements>.header .search .form-control:-ms-input-placeholder {
   font-size: .75rem
}

#vvveb-builder .drag-elements>.header .search .form-control::placeholder {
   font-size: .75rem
}

#vvveb-builder .drag-elements .nav-item .nav-link {
   text-align: center;
   border-top-color: #fff;
   border-radius: 0;
   min-width: 4.5rem;
   color: #212529;
   line-height: 1.2rem
}

#vvveb-builder .drag-elements .nav-item .nav-link div {
   margin-top: .2rem
}

#vvveb-builder .drag-elements .nav-item .nav-link i.la-lg {
   font-size: 1.4rem;
   margin: .2rem 0
}

#vvveb-builder .drag-elements .nav-item .nav-link .small, #vvveb-builder .drag-elements .nav-item .nav-link small {
   font-size: .8rem;
   display: none
}

#vvveb-builder .drag-elements .nav-item .nav-link.active, #vvveb-builder .drag-elements .nav-item .nav-link:hover {
   color: #0d6efd
}

#vvveb-builder .component-properties {
   height: 98%;
   height: calc(100% - 340px);
   height: calc(100% - var(--builder-filemanager-height) - 90px + 100);
   height: 300%;
   list-style: none;
   background: #fafafa
}

#vvveb-builder .component-properties::-webkit-scrollbar {
   width: .5rem;
   background-color: hsla(0, 0%, 100%, .1);
   -webkit-box-shadow: none
}

#vvveb-builder .component-properties::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, .05)
}

#vvveb-builder .component-properties::-webkit-scrollbar-thumb {
   background-color: rgba(0, 0, 0, .15);
   outline: 1px solid #708090
}

#vvveb-builder .component-properties {
   background: #fff;
   height: 95%;
   height: calc(100% - 50px)
}

#vvveb-builder .component-properties .tab-content {
   overflow-y: scroll;
   position: relative;
   height: 93%
}

#vvveb-builder .component-properties .nav-tabs {
   background: #f9f9f9
}

#vvveb-builder .component-properties #right-panel {
   color: #777
}

#vvveb-builder .component-properties, #vvveb-builder .component-properties input, #vvveb-builder .component-properties select {
   font-size: 12px
}

#vvveb-builder .component-properties .form-select {
   height: auto
}

#vvveb-builder .component-properties .mb-3.row {
   margin: 0
}

#vvveb-builder .component-properties .mb-3>label i {
   font-size: 14px
}

#vvveb-builder .component-properties .mb-3 .input .input-range {
   position: relative
}

#vvveb-builder .component-properties .mb-3 .input .input-range input[type=number] {
   position: absolute;
   right: 0;
   top: -1.8rem;
   width: 5em;
   padding: .3rem .5rem;
   text-align: center;
   line-height: 12px
}

#vvveb-builder .component-properties .mb-3.inline {
   display: flex
}

#vvveb-builder .component-properties .mb-3.inline .input {
   flex: 1 1 auto;
   width: auto
}

#vvveb-builder #canvas iframe, #vvveb-builder #canvas iframe body {
   width: 100%;
   height: 100%;
   border: none
}

#vvveb-builder #canvas.mobile {
   width: 320px !important;
   left: calc(50% - 175vw);
   left: calc(50% - 160px - var(--builder-left-panel-width))
}

#vvveb-builder.preview #canvas {
   width: 100%;
   margin-left: 0;
   margin-right: 0
}

#vvveb-builder.preview #right-panel {
   display: none
}

#vvveb-builder ::-webkit-scrollbar {
   width: .8em;
   background: #fafafa
}

#vvveb-builder ::-webkit-scrollbar-thumb {
   background: #ccc
}

#vvveb-builder .nav-tabs .nav-item:first-child .nav-link {
   border-left: none
}

#vvveb-builder .nav-tabs .nav-link {
   padding: .8rem 0 .5rem;
   text-align: center
}

#vvveb-builder .nav-tabs .nav-link i {
   font-size: 1.4rem
}

#vvveb-builder .nav-tabs .nav-item.show .nav-link, #vvveb-builder .nav-tabs .nav-link.active {
   background-color: #fff;
   border-color: #0d6efd #dee2e6 #fff;
   border-top-width: 2px;
   border-radius: 0
}

#vvveb-builder .nav-fill .nav-link {
   padding-left: 0;
   padding-right: 0
}

li[data-type] a {
   min-height: 20px;
   white-space: break-spaces;
   padding: 0 3px
}

.drag-elements-sidepane {
   z-index: 0;
   margin: 0;
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
   width: 100%
}

.drag-elements-sidepane::-webkit-scrollbar {
   width: .5rem;
   background-color: hsla(0, 0%, 100%, .1);
   -webkit-box-shadow: none
}

.drag-elements-sidepane::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, .05)
}

.drag-elements-sidepane::-webkit-scrollbar-thumb {
   background-color: rgba(0, 0, 0, .15);
   outline: 1px solid #708090
}

.drag-elements-sidepane>div:hover {
   height: 100vh;
   overflow-y: auto
}

.drag-elements-sidepane ul {
   z-index: 1;
   margin: 0;
   padding: 0;
   white-space: nowrap;
   text-align: center
}

.drag-elements-sidepane ul>li {
   list-style: none;
   float: none;
   clear: both
}

.drag-elements-sidepane ul>li.header {
   height: auto;
   margin: 0;
   padding: 0;
   width: 100%;
   position: relative
}

.drag-elements-sidepane ul>li.header label {
   font-size: 11px;
   font-weight: 600;
   line-height: 28px;
   text-align: left;
   padding: .5rem 1.8rem
}

.drag-elements-sidepane ul>li.header label>a {
   padding-left: 1rem;
   color: #007bff
}

.drag-elements-sidepane ul>li.header:first-child {
   margin-top: 0;
   border-top: none
}

.drag-elements-sidepane ul>li ol {
   margin: 0;
   padding: 0
}

.drag-elements-sidepane ul>li ol li {
   list-style: none;
   float: left
}

.drag-elements-sidepane ul>li ol li a {
   color: #333;
   text-decoration: none;
   text-shadow: none
}

.drag-elements-sidepane ul>li ol li[data-type] {
   border: none;
   list-style: none
}

.drag-elements-sidepane ul>li ol li[data-type]:hover {
   cursor: hand;
   cursor: grab;
   cursor: -webkit-grab;
   background-color: #fff;
   opacity: 1;
   text-align: center
}

.drag-elements-sidepane ul>li ol li[data-type]:hover a {
   display: block
}

.blocks .drag-elements-sidepane li[data-type], .sections .drag-elements-sidepane li[data-type] {
   width: 92%;
   height: 180px;
   position: relative;
   text-align: center;
   font-weight: 400;
   font-size: 11px;
   color: #000;
   background-repeat: no-repeat;
   padding-top: 0;
   display: block;
   border: 1px solid #ddd;
   border-radius: 0;
   background-position: 50%;
   box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .15);
   -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .15);
   background-size: 100%;
   z-index: 100;
   background-color: #fff;
   cursor: grab;
   opacity: 1;
   margin: 1rem
}

.blocks .drag-elements-sidepane li[data-type] a.name, .sections .drag-elements-sidepane li[data-type] a.name {
   background: rgba(0, 0, 0, .7);
   background: rgba(13, 110, 253, .8);
   background: hsla(0, 0%, 100%, .9);
   color: #333;
   padding: .5rem 0;
   position: absolute;
   bottom: 0;
   width: 100%;
   left: 0;
   font-size: 12px;
   border-top: 1px solid rgba(0, 0, 0, .1)
}

.blocks .drag-elements-sidepane li[data-type]:hover, .sections .drag-elements-sidepane li[data-type]:hover {
   border-color: #0d6efd;
   box-shadow: 0 1px 5px 0 rgba(13, 110, 253, .15);
   -webkit-box-shadow: 0 1px 5px 0 rgba(13, 110, 253, .15)
}

.blocks .drag-elements-sidepane li[data-type]:hover:before, .sections .drag-elements-sidepane li[data-type]:hover:before {
   opacity: .3
}

.blocks .drag-elements-sidepane li[data-type]:hover a.name, .sections .drag-elements-sidepane li[data-type]:hover a.name {
   display: block
}

.blocks .drag-elements-sidepane li[data-type]:before, .sections .drag-elements-sidepane li[data-type]:before {
   content: " ";
   background: #fff;
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;
   opacity: 0;
   transition: opacity .7s
}

.blocks .drag-elements-sidepane li[data-type] a.name, .blocks .drag-elements-sidepane li[data-type] img.preview, .sections .drag-elements-sidepane li[data-type] a.name, .sections .drag-elements-sidepane li[data-type] img.preview {
   display: none
}

.components .drag-elements-sidepane li[data-type] {
   margin: 8px 0;
   width: 100%;
   min-width: 100px;
   height: 100px;
   flex-direction: column;
   display: flex;
   text-align: center;
   font-weight: 400;
   font-size: 11px;
   color: #000;
   background-repeat: no-repeat;
   /* padding-top: 60px; */
   padding-bottom: 7px;
   border: 1px solid #e5e5e5;
   border-radius: 3px;
   background-position: 50% 30%;
   background-size: auto 42px;
   z-index: 100;
   /* background-color: transparent; */
   cursor: grab;
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .05);
   -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .05);
   background-color: #fff
}

.components .drag-elements-sidepane li[data-type] a {
   min-height: 20px;
   white-space: break-spaces;
   padding: 0 3px
}

.components .drag-elements-sidepane li[data-type]:hover {
   box-shadow: 0 0 1px 0 #4285f4;
   background-color: rgba(66, 133, 244, .1)
}

.toggle {
   position: relative;
   width: 65px;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none
}

.form-select {
   -webkit-appearance: none;
   -moz-appearance: none
}

input[type=number] {
   -webkit-appearance: textfield;
   appearance: textfield
}

input[type=number]:focus, input[type=number]:hover {
   -webkit-appearance: auto;
   appearance: auto
}

.form-select.small-arrow {
   background-position: right .5rem center;
   background-size: 10px 8px
}

.input-group.auto input[type=number] {
   display: none
}

.input-group.auto .input-group-append {
   width: 100%
}

.btn-group.btn-group-fullwidth {
   display: flex;
   width: 100%
}

.btn-group.btn-group-fullwidth .btn {
   flex: 1 1 auto
}

.btn-group .btn i {
   vertical-align: middle
}

.form-control::-webkit-input-placeholder {
   opacity: .7
}

.form-control:-ms-input-placeholder {
   opacity: .7
}

.form-control::placeholder {
   opacity: .7
}

.form-control, .form-select {
   box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, .05)
}

ul.autocomplete {
   position: absolute;
   overflow: hidden;
   background-color: #fff;
   border: 1px solid #ccc;
   border-top: none;
   margin: 0;
   padding: 0;
   list-style: none;
   color: #000;
   display: none;
   z-index: 1000
}

ul.autocomplete li {
   display: block;
   padding: .3em;
   overflow: hidden;
   width: 100%;
   cursor: pointer
}

ul.autocomplete li.selected {
   background-color: Highlight;
   color: #fff
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active {
   box-shadow: 0 0 1px 0 hsla(0, 0%, 100%, .1);
   background-color: rgba(66, 133, 244, .07);
   border-radius: 0;
   border-color: transparent
}

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus {
   box-shadow: 0 0 1px 0 rgba(66, 133, 244, .2)
}

.dot {
   position: absolute;
   margin: auto
}

.dot {
   width: 50px;
   height: 50px;
   border-radius: 50%;
   background-color: hsla(0, 0%, 100%, .7);
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   line-height: 49px;
   text-align: center
}

@-webkit-keyframes dot-3-move {
   20% {
      -webkit-transform: scale(1);
      transform: scale(1)
   }

   45% {
      -webkit-transform: translateY(-18px) scale(.45);
      transform: translateY(-18px) scale(.45)
   }

   60% {
      -webkit-transform: translateY(-90px) scale(.45);
      transform: translateY(-90px) scale(.45)
   }

   80% {
      -webkit-transform: translateY(-90px) scale(.45);
      transform: translateY(-90px) scale(.45)
   }

   to {
      -webkit-transform: translateY(0) scale(1);
      transform: translateY(0) scale(1)
   }
}

@keyframes dot-3-move {
   20% {
      -webkit-transform: scale(1);
      transform: scale(1)
   }

   45% {
      -webkit-transform: translateY(-18px) scale(.45);
      transform: translateY(-18px) scale(.45)
   }

   60% {
      -webkit-transform: translateY(-90px) scale(.45);
      transform: translateY(-90px) scale(.45)
   }

   80% {
      -webkit-transform: translateY(-90px) scale(.45);
      transform: translateY(-90px) scale(.45)
   }

   to {
      -webkit-transform: translateY(0) scale(1);
      transform: translateY(0) scale(1)
   }
}

@-webkit-keyframes dot-2-move {
   20% {
      -webkit-transform: scale(1);
      transform: scale(1)
   }

   45% {
      -webkit-transform: translate(-16px, 12px) scale(.45);
      transform: translate(-16px, 12px) scale(.45)
   }

   60% {
      -webkit-transform: translate(-80px, 80px) scale(.45);
      transform: translate(-80px, 80px) scale(.45)
   }

   80% {
      -webkit-transform: translate(-80px, 80px) scale(.45);
      transform: translate(-80px, 80px) scale(.45)
   }

   to {
      -webkit-transform: translateY(0) scale(1);
      transform: translateY(0) scale(1)
   }
}

@keyframes dot-2-move {
   20% {
      -webkit-transform: scale(1);
      transform: scale(1)
   }

   45% {
      -webkit-transform: translate(-16px, 12px) scale(.45);
      transform: translate(-16px, 12px) scale(.45)
   }

   60% {
      -webkit-transform: translate(-80px, 80px) scale(.45);
      transform: translate(-80px, 80px) scale(.45)
   }

   80% {
      -webkit-transform: translate(-80px, 80px) scale(.45);
      transform: translate(-80px, 80px) scale(.45)
   }

   to {
      -webkit-transform: translateY(0) scale(1);
      transform: translateY(0) scale(1)
   }
}

@-webkit-keyframes dot-1-move {
   20% {
      -webkit-transform: scale(1);
      transform: scale(1)
   }

   45% {
      -webkit-transform: translate(16px, 12px) scale(.45);
      transform: translate(16px, 12px) scale(.45)
   }

   60% {
      -webkit-transform: translate(80px, 70px) scale(.45);
      transform: translate(80px, 70px) scale(.45)
   }

   80% {
      -webkit-transform: translate(80px, 60px) scale(.45);
      transform: translate(80px, 60px) scale(.45)
   }

   to {
      -webkit-transform: translateY(0) scale(1);
      transform: translateY(0) scale(1)
   }
}

@keyframes dot-1-move {
   20% {
      -webkit-transform: scale(1);
      transform: scale(1)
   }

   45% {
      -webkit-transform: translate(16px, 12px) scale(.45);
      transform: translate(16px, 12px) scale(.45)
   }

   60% {
      -webkit-transform: translate(80px, 70px) scale(.45);
      transform: translate(80px, 70px) scale(.45)
   }

   80% {
      -webkit-transform: translate(80px, 60px) scale(.45);
      transform: translate(80px, 60px) scale(.45)
   }

   to {
      -webkit-transform: translateY(0) scale(1);
      transform: translateY(0) scale(1)
   }
}

@-webkit-keyframes rotate-move {
   55% {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
      transform: translate(-50%, -50%) rotate(0deg)
   }

   80% {
      -webkit-transform: translate(-50%, -50%) rotate(1turn);
      transform: translate(-50%, -50%) rotate(1turn)
   }

   to {
      -webkit-transform: translate(-50%, -50%) rotate(1turn);
      transform: translate(-50%, -50%) rotate(1turn)
   }
}

@keyframes rotate-move {
   55% {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
      transform: translate(-50%, -50%) rotate(0deg)
   }

   80% {
      -webkit-transform: translate(-50%, -50%) rotate(1turn);
      transform: translate(-50%, -50%) rotate(1turn)
   }

   to {
      -webkit-transform: translate(-50%, -50%) rotate(1turn);
      transform: translate(-50%, -50%) rotate(1turn)
   }
}

@-webkit-keyframes index {
   0%, to {
      z-index: 3
   }

   33.3% {
      z-index: 2
   }

   66.6% {
      z-index: 1
   }
}

@keyframes index {
   0%, to {
      z-index: 3
   }

   33.3% {
      z-index: 2
   }

   66.6% {
      z-index: 1
   }
}

input[type=color] {
   width: 28px;
   height: 28px;
   border-radius: 3px;
   padding: 2px;
   box-shadow: none
}

@-webkit-keyframes popup-animation {
   0% {
      -webkit-transform: translateY(-3rem) scaleY(0) scaleX(0);
      transform: translateY(-3rem) scaleY(0) scaleX(0)
   }

   to {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
      transform: translateY(0) scaleY(1) scaleX(1)
   }
}

@keyframes popup-animation {
   0% {
      -webkit-transform: translateY(-3rem) scaleY(0) scaleX(0);
      transform: translateY(-3rem) scaleY(0) scaleX(0)
   }

   to {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
      transform: translateY(0) scaleY(1) scaleX(1)
   }
}

.btn-icon {
   font-weight: 500;
   text-transform: capitalize;
   margin-bottom: 0;
   padding: .5rem 2rem
}

.btn-icon i {
   display: inline-block;
   margin-right: .3rem;
   opacity: .7
}

.btn-icon.btn-outline-primary {
   border-width: 0;
   box-shadow: 0 0 1px 0 #3478f2;
   background-color: #fff
}

.btn-icon.btn-outline-primary:hover {
   color: #0d6efd;
   background: #f8f9fa
}

.btn-icon.btn-outline-primary i {
   background: transparent
}

.btn-icon.btn-primary {
   box-shadow: -1px 1px 2px 1px rgba(0, 0, 0, .07), inset -1px 1px 2px 0 hsla(0, 0%, 100%, .15)
}

.alert-light {
   color: #525252;
   background-color: #f5f5f5;
   border-color: #f0f0f0
}

::-webkit-input-placeholder {
   color: #4d535e
}

::-moz-placeholder {
   color: #4d535e;
   opacity: 1
}

@-webkit-keyframes showSlowlyElement {
   to {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1
   }

   0% {
      -webkit-transform: scale(.9);
      transform: scale(.9);
      opacity: 0
   }
}

@keyframes showSlowlyElement {
   to {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1
   }

   0% {
      -webkit-transform: scale(.9);
      transform: scale(.9);
      opacity: 0
   }
}

.files .image {
   display: inline-block;
   margin: 0 10px 0 0;
   max-width: 200px;
   max-height: 120px;
   background-position: 50%;
   background-size: 100%;
   background-repeat: no-repeat;
   float: left
}

.files .preview {
   display: none;
   position: absolute;
   top: 0;
   right: 0;
   z-index: 10000;
   max-width: 50%;
   max-height: 100%;
   border-left: 1px solid #ccc;
   border-bottom: 1px solid #ccc;
   background: #fff
}

.files .preview>img {
   display: block;
   margin: auto
}

.files .preview>div {
   padding: 2rem
}

.icon {
   font-size: 23px;
   float: left
}

.icon.file {
   width: 2.5em;
   height: 3em;
   line-height: 3em;
   text-align: center;
   border-radius: .25em;
   color: #fff;
   display: inline-block;
   margin: 15px 20px 0 5px;
   position: relative;
   overflow: hidden;
   box-shadow: inset 1.74em -2.1em 0 0 #a4a7ac
}

.icon.file:first-line {
   font-size: 13px;
   font-weight: 500
}

.icon.file:after {
   content: "";
   position: absolute;
   z-index: -1;
   border-width: 0;
   border-bottom: 2.6em solid #dadde1;
   border-right: 2.22em solid transparent;
   top: -34.5px;
   right: -4px
}

body {
   scrollbar-color: rgba(0, 0, 0, .1) #fff;
   scrollbar-width: thin
}

body::-webkit-scrollbar {
   width: .5rem;
   background-color: hsla(0, 0%, 100%, .1);
   -webkit-box-shadow: none
}

body::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, .05)
}

body::-webkit-scrollbar-thumb {
   background-color: rgba(0, 0, 0, .15);
   outline: 1px solid #708090
}

[contenteditable=true], [contenteditable=true]:focus-visible {
   outline: none
}

@-webkit-keyframes blink {
   50% {
      background-color: transparent
   }
}

@keyframes blink {
   50% {
      background-color: transparent
   }
}

[selectable] {
   position: relative
}

.unselectable {
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
   -webkit-user-drag: none
}

.highlight_icon {
   height: 17px;
   padding: 6px;
   cursor: pointer
}

.canvas {
   overflow-y: auto
}

.col {
   position: relative
}

#top-panel .metadata {
   margin-top: 8px;
   margin-left: 10px
}

#top-panel .metadata .content {
   margin-left: 10px
}

.text-center {
   text-align: center;
}
.text-right {
   text-align: right;
}
.text-left {
   text-align: left;
}
