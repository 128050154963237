
.component_container {
    position: relative;
    display: block;
    box-sizing: border-box;
    // user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    // z-index: 1;


    &:hover:after, &:hover:before  {
        // outline: 3px solid #0198fa;
       //  box-shadow: 0 0 0 3px #0198fa inset;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border: 3px solid #0198fa;
        top: 0;
        left: 0;
        box-sizing: border-box;
        pointer-events: none;
        z-index: 9;
    }

    &.hover:after,  &.hover:before  {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border: 3px solid #0198fa ;
        top: 0;
        left: 0;
        box-sizing: border-box;
        pointer-events: none;
        z-index: 9;
    }

    .component_highlight {
        position: absolute;
        display: flex;
        height: 30px;
        top: -28px;
        right: 0px;
        pointer-events: auto;
        color: rgb(255, 255, 255);
        background-color:  #007bff;
        font-size: 16px;
        padding: 2px 5px;

        .highlight_icon {
            height: 17px;
            padding: 6px;
            color: #fff;
            cursor: pointer;

            :hover {
                color: #0a58ca;
            }
        } 
        
    } 
} 



