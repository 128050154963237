.modal_content {
  top: 50%;
  left: 50%;
  width: 870px;
  height: auto;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  // padding: 20px;
  
  .close {
    font-size: 32px;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }
  
  &__header {
    padding: 15px;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

  }
  
  &__body {
    padding: 15px;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

  }
  
  &__footer {

  }
}


